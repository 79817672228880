<template>
<div>
    <!-- leaddetails-left -->
    <div class="leaddetails-left">
        <div class="vehicle-interest border-bottom">
            <div class="detais-search">
                <p class="fw-bold fs-5 text-center">All Conversations</p>
                <div class="form-group Vehicle">
                    <input type="search" class="form-control" placeholder="Search User" />
                    <img class="img-fluid Vehi-img" src="@/assets/images/icons/search.svg" alt="" />
                </div>
            </div>
        </div>
        <div class="card rounded-3">
            <div class="card-body mt-0">
                <div class="media border-bottom msg-person" v-for="(conv,index) in conversations" :key="index">
                    <a href="#!" class="p-2 d-flex" :class="activeConv.booking && conv.booking.tracking_id == activeConv.booking.tracking_id ? 'active':''" v-on:click.prevent="getMessages(conv)">
                        <div class="me-2">
                            <img v-if="conv.business.profile_pic" :src="conv.business.profile_pic" class="img-fluid" alt="Business Logo"/>
                            <img v-else class="img-fluid" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg" alt="Profile Picture">
                            <span class=" green-con" id="user-active-1" ></span>
                        </div>
                        <div class="media-body">
                            <h5 class="mb-0">{{conv.business.title}}</h5>
                            <p class="mb-0 text-dark">Order ID: #{{conv.booking.tracking_id}}</p>
                        </div>
                    </a>
                </div>
                <button data-bs-toggle="modal" data-bs-target="#exampleModal" class="ond-btn-p mx-auto my-3" v-if="conversations.length == 0"> Start new conversation</button>
            </div>
        </div>
    </div>
    <!-- leaddetails-left -->

    <!-- leaddetails-mid -->
    <div class="leaddetails-mid m-0">
        <div class="activity-area m-0">
            <div class="card rounded-3" v-if="Object.keys(activeConv).length > 0">
                <div class="card-header border-bottom">
                    <div class="media p-2 border-bottom border-light w-100">
                        <div class="d-flex me-3">
                            <img v-if="activeConv.business.profile_pic" class="media-object rounded-circle" :src="activeConv.business.profile_pic" alt="" style="width: 40px">
                            <img v-else class="media-object rounded-circle" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg" alt="" style="width: 40px">
                        </div>
                        <div class="media-body msg-person mt-2">
                            <h5 class="mb-0 text-dark">{{activeConv.business.title}}</h5>
                            <!-- <p class="mb-0" v-if="activeConv.user.city">{{activeConv.user.city}}, {{activeConv.user.state}}</p> -->
                        </div>

                        <a href="#" class="btn-start-new-chat float-right" data-bs-toggle="modal" data-bs-target="#exampleModal"><img class="img-fluid" src="@/assets/images/icons/add.svg" alt=""></a>
                    </div>
                </div>
                <div class="card-body mt-0">
                    <div class="message-content border-0" id="messagesSection">
                        <div class="message-contentss chatbot">
                            <div class="activity border-top-0" v-for="(msg,index) in messages" :key="index">
                                <div class="activity-icon" v-if="msg.sender_type == 1">
                                    <img v-if="msg.business.profile_pic" class="img-fluid rounded-circle" :src="msg.business.profile_pic" alt="" />
                                    <img v-else class="img-fluid rounded-circle" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg" alt="" />
                                </div>
                                <div class="activity-icon" v-if="msg.sender_type == 2">
                                    <img v-if="msg.user.picture" class="img-fluid rounded-circle" :src="msg.user.picture" alt="" />
                                    <img v-else class="img-fluid rounded-circle" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" alt="" />
                                </div>
                                <div class="activity-text" v-if="msg.sender_type == 1">
                                    <h5>{{msg.business.title}} <span class="msg-date">{{changeDateFormate(msg.created_at,'h:mm a')}}</span></h5>
                                    <!-- changeDateFormate(msg.created_at,'MMMM DD, YYYY h:mm a') -->
                                    <p>{{msg.message}}</p>
                                </div>
                                <div class="activity-text" v-if="msg.sender_type == 2">
                                    <h5>{{msg.user.name}} <span class="msg-date">{{changeDateFormate(msg.created_at,'h:mm a')}}</span></h5>
                                    <p>{{msg.message}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="message-box">
                        <div id="typing_on" style="color: #717171;"></div>
                        <button class="message-submit" v-on:click="sendMessage" v-on:keyup.enter="sendMessage"><img class="img-fluid" src="@/assets/images/icons/sendmsg.svg" alt=""></button>
                        <div class="input-group">
                            <!-- <input type="text" id="msg_input" class="form-control border chat-input" v-model="msg_.message" placeholder="Type here..."> -->
                            <textarea @keydown.enter="handleEnter" id="msg_input" class="form-control border chat-input" v-model="msg_.message" placeholder="Type here..."></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body mt-0" v-else>
                <div class="text-center p-5">
                    <img src="@/assets/images/messages/no-msg.svg" style="width: 125px">
                    <h3><span v-if="conversations.length > 0">Select a Conversation</span><span v-if="conversations.length == 0">You don’t have any conversation yet</span></h3>
                    <!-- <p class="text-muted">The messages you get here should be taken seriously because they either came directly from a client that booked or a client requesting a quote.</p> -->
                </div>
            </div>
        </div>
    </div>
    <!-- leaddetails-mid -->

    <!-- leaddetails-right -->
    <div class="leaddetails-right" v-if="Object.keys(activeConv).length > 0">
        <div class="appointments p-0">
            <div class="details-profile">
                <img v-if="activeConv.business.profile_pic" :src="activeConv.business.profile_pic" class="img-fluid rounded-circle" alt="Profile Picture"/>
                <img v-else class="img-fluid rounded-circle" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg" alt="Profile Picture" />
                <h3>{{activeConv.business.title}}</h3>
                <!-- <table class="table mb-2">
                    <tbody>
                        <tr>
                            <td>Email:</td>
                            <td>{{activeConv.user.email}}</td>
                        </tr>
                        <tr>
                            <td>Phone:</td>
                            <td>{{activeConv.user.phone}}</td>
                        </tr>
                    </tbody>
                </table> -->
                <div class="row pt-2">
                    <p class="col-md-5 text-start">Order Detail:</p>
                    <p class="col-md-7 text-right" v-if="activeConv.req_a_quote_id"><button>Make New Offer</button></p>
                </div>
                <table class="table">
                    <tbody>
                        <tr>
                            <td>Order ID:</td>
                            <td>#{{activeConv.booking.tracking_id}}</td>
                        </tr>
                        <tr>
                            <td>Location:</td>
                            <td>
                                <span v-if="activeConv.booking.service_rendered == 1">At Business</span>
                                <span v-if="activeConv.booking.service_rendered == 2">Client Preference</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Price:</td>
                            <td>${{activeConv.booking.booking_price}}</td>
                        </tr>
                        <tr>
                            <td>Date:</td>
                            <td>{{activeConv.booking.booking_date}} {{activeConv.booking.booking_start_time}}</td>
                        </tr>
                        <tr>
                            <td>Status:</td>
                            <td>
                                <span v-if="activeConv.booking.booking_status == 0" class="status status-blue">Pending</span>
                                <span class="status status-green" v-if="activeConv.booking.booking_status == 1">Start</span>
                                <span v-if="activeConv.booking.booking_status == 2" class="status status-red">Cancel</span>
                                <span class="status status-green" v-if="activeConv.booking.booking_status == 3">Complete</span>
                                <span v-if="activeConv.booking.booking_status == 4" class="status status-blue">No-Show</span>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="team-tags" v-if="activeConv.booking_id">
                    <p>Services</p>
                    <ul>
                        <li v-for="serv in activeConv.booking.boooking_services" :key="serv.id"><a href="#">{{serv.title}}</a></li>
                    </ul>
                </div>
                <div class="row border-top pt-3" v-if="activeConv.req_a_quote_id">
                    <p class="col-md-12 text-left">Previous Offers:</p>
                </div>
                <table class="table" v-if="activeConv.req_a_quote_id">
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Amount</th>
                            <th style="text-align:center">Date</th>
                            <th>Services</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td style="text-align:center">$40</td>
                            <td>2022/04/23</td>
                            <td>Hair Color</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td style="text-align:center">$30</td>
                            <td>2022/04/24</td>
                            <td>Facial Wax</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- leaddetails-right -->


    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Start New Chat</h5>
                    <a class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img class="img-fluid" src="@/assets/images/icons/Closex.svg" alt=""></span>
                    </a>
                </div>
                <div class="modal-body">
                    <div class="add-member add-trades">
                        <form class="test" method="post">
                            <div class="form-group">
                                <label>Select Order</label>
                                <select class="form-control mt-1" v-model="conv_.booking">
                                    <option value="">Choose order</option>
                                    <option v-for="order in orders" :key="order.id" :value="order">#{{order.tracking_id}} - {{order.business.title}}</option>
                                </select>
                            </div>
                            <!-- <div class="form-group">
                                <label for="inputAddress22">Select Order</label>
                                <Multiselect v-model="conv_.booking_id" :options="options" placeholder='Search Order..' :searchable="true"></Multiselect>
                            </div> -->
                            <div class="form-group">
                                <label for="inputAddress">Message</label>
                                <textarea class="form-control" v-model="conv_.message" rows="3" placeholder="Type here..."></textarea>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#" data-bs-dismiss="modal" aria-label="Cancel" class="add-btn">Cancel</a>
                    <a href="#" v-on:click.prevent="makeConversation" class="rem-btn">Mesage</a>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
    import axios from 'axios';
    import  io  from 'socket.io-client';
    import moment from 'moment'
    import $ from 'jquery';
    var socket = io();
    var user;
    var typing = false;
    var timeout = undefined;

    export default {
        data() {
            return {
                orders:[],
                conversations:[],
                conv_:{
                    message:'',
                    booking:'',
                    from:'client'
                },
                activeConv:{},
                messages:[],
                user:{},
                msg_:{
                    message:'',
                    conversation_id:'',
                    msg_from:'client',
                    receiver_id: ''
                }
            }
        },
        created(){
            this.getConverstaions();
            this.getOrders();

            if (this.$route.params.id != '' && this.$route.params.id != undefined && this.$route.params.id != null && this.$route.params.id != 'undefined') {
                this.getConversationDetail(this.$route.params.id,this.$route.params.type)
            }

            //alert(this.$route.params.id)
            //alert(this.$route.params.type)

            user = this.$storage.getStorageSync('user');
            socket.on('connect', function() {
                socket.emit('user_connected', user.id);
            });
        },
        methods:{
            getConverstaions(id=''){
                let thiss = this
                axios.get('converstaions/client').then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.conversations = response.data.data
                        if (id != '') {
                            response.data.data.forEach((conv) => {
                                if (conv.id == id) {
                                    //thiss.activeConv = conv;
                                    thiss.getMessages(conv);
                                }
                            });
                        }
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            getOrders(){
                // Get those orders who dont has conversation with this business
                let thiss = this
                axios.get('orders_for_conversation/client').then(function (response) {

                    if (response.data.status == 'success') {
                        thiss.orders = response.data.data
                        console.log(thiss.orders)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            makeConversation(){
                let thiss = this
                axios.post('create_conversation',this.conv_).then(function (response) {
                    if (response.data.status == 'success') {
                        $('#exampleModal').modal('hide');
                        thiss.getConverstaions(response.data.data)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            getConversationDetail(id,type=''){
                let thiss = this
                axios.get('converstaion_detail/client/0/'+id+'/'+type).then(function (response) {
                    if (response.data.status == 'success') {
                        console.log(response.data.data)
                        if (response.data.data.id != undefined && response.data.data.id != '' && response.data.data.id != null && response.data.data.id != 'undefined' ) {
                            //thiss.activeConv = response.data.data;
                            thiss.getMessages(response.data.data)
                        }
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            getMessages(conv){
                this.activeConv = conv
                this.msg_.conversation_id = conv.id;
                this.msg_.receiver_id = conv.business.id
                let thiss = this
                axios.get('messages/'+conv.id).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.messages = response.data.data
                        setTimeout(function () {
                            thiss.scrollToEnd();
                        }, 1000);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            handleEnter (e) {
                if (e.ctrlKey) {
                    //alert('ctel enter')
                    let caret = e.target.selectionStart;
                    e.target.setRangeText("\n", caret, caret, "end");
                    this.msg_.message = e.target.value;
                    //this.msg_.message+ "\n";
                }else{
                    e.preventDefault();
                    this.sendMessage();
                }
            },
            sendMessage(){
                if (this.msg_.message != '' && this.msg_.message != undefined && this.msg_.message != null && this.msg_.message != 'undefined') {
                    //console.log(this.activeConv.business,this.msg_,'send')
                    let thiss = this
                    axios.post('send_message',this.msg_).then(function (response) {
                        if (response.data.status == 'success') {
                            //socket.emit("getMessage", thiss.msg_ );
                            thiss.appendMessage(thiss.msg_)
                            thiss.msg_.message = ''
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error.response.data.message);
                        }
                    });
                }
            },
            appendMessage(msg) {
                let name = this.$storage.getStorageSync('user').name
                let image = this.$storage.getStorageSync('user').picture
                if (image == undefined || image == null || image == '' || image == 'undefined') {
                    image = 'https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg'
                }

                $(".chatbot").append(
                '<div class="activity border-top-0">'+
                    '<div class="activity-icon">'+
                        '<img class="img-fluid" src="'+image+'" alt="" />'+
                    '</div>'+
                    '<div class="activity-text">'+
                        '<h5>'+name+' <span class="msg-date">'+this.changeDateFormate("","h:mm a")+'</span></h5>'+
                        '<p>'+msg.message+'</p>'+
                    '</div>'+
                '</div>'
                );
                this.scrollToEnd();
            },
            scrollToEnd: function () {
                var container = this.$el.querySelector("#messagesSection");
                container.scrollTop = container.scrollHeight;
            },
            changeDateFormate(date='',formate){
                if (date == '') {
                    return moment().format(formate)
                }
                return moment(date).format(formate)
            },



            timeoutFunction(){
                typing = false;
                socket.emit('stopped_typing',this.msg_);
            },
            onKeyDownNotEnter(){
                if(typing == false) {
                    typing = true
                    socket.emit('is_typing',  this.msg_);
                    timeout = setTimeout(this.timeoutFunction(), 1000);
                } else {
                    clearTimeout(timeout);
                    timeout = setTimeout(this.timeoutFunction(), 1000);
                }
            },

        },
        mounted() {
            let thiss = this
            socket.on("sendToClient",(data)=>{
                thiss.appendMessage(data);
            });
            socket.on("typing",(data)=>{
                $('#typing_on').html(data.name+" is typing ...");
            });
            socket.on("stopedyping",()=>{
                $('#typing_on').html("");
            });

            socket.on('updateUserStatus', (data) => {
                // let $userStatusIcon = $('.chat-user-online1');
                $.each(data, function (key, val) {
                    if (val !== null && val !== 0) {
                        let $userIcon = $("#user-active-"+key);
                        $userIcon.addClass('user-active');
                    }
                });
            });


            // let $chatInput = $(".chat-input");
            // $chatInput.keypress(function (e) {
            //     alert()
            //     if (e.which === 13 && !e.shiftKey) {
            //         $('#typing_on').html("");
            //         $chatInput.html("");
            //         $( "#msg_input" ).val('');
            //         thiss.sendMessage(thiss.msg_);
            //     }else{
            //         thiss.onKeyDownNotEnter();
            //     }
            // });
        }
    };
</script>
<style type="text/css" scopped>
    a.add-btn, button.add-btn {
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border: 1px solid #A2A8B8;
        background: #fff;
        padding: 8px 25px;
        border-radius: 30px;
        text-transform: capitalize;
        line-height: 1;
        color: #6D778E;
        font-size: 14px;
        font-weight: 700;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content
    }
    a.add-btn img, button.add-btn img {
        height: 16px;
        margin-right: 5px
    }
    a.rem-btn,button.rem-btn {
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border: 1px solid #6e317a;
        background: #6e317a;
        padding: 8px 25px;
        border-radius: 30px;
        text-transform: capitalize;
        line-height: 1;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: max-content
    }
    a.rem-btn img, button.rem-btn img {
        height: 16px;
        margin-right: 5px;
    }
    .left_overflow, .right_overflow{
        overflow-y: scroll;
        max-height: 450px
    }
    .activity-text h5{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 2px
    }
    .activity-text h5 span.msg-date{
        font-size: 12px;
        font-weight: bold;
        color: #939393;
        margin-left: 6px;
    }
    .activity-text p{
        color:#717171;
    }
    .btn-start-new-chat{
        background: #6e317a;
        padding-top: 6px;
        border: 1px solid #6e317a;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-weight: normal;
        color: rgb(255, 255, 255);
        text-align: center;
        margin-top: 2px;
        margin-right: 4px;
    }
    .btn-start-new-chat img{
        width: 17px;
    }
    .msg-person:last-child{
        border-bottom: none !important
    }
    .msg-person a{
        width: 100%;
        color: #333;
    }
    .msg-person a:hover, .msg-person a:active,
    .msg-person a.active{
        text-decoration: none;
        background-color: #f9faff;
        color: #8e449d;
    }
    .msg-person a.active{
        border-right: 3px solid #8e449d;
    }
    .msg-person img{
        max-width: 40px;
        border-radius: 50%;
    }
    .msg-person h5{
        font-size: 15px;
        font-weight: 500
    }
    .msg-person p{
        font-size: 12px;
        font-weight: normal;
    }
    .media-body{
        flex: 1
    }
    .leaddetails-left {
        width: 21%;
        float: left;
        background: #FFFFFF;
        border-radius: 10px;
        margin-right: 2%;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 8px rgb(49 81 161 / 8%)
    }

    .leaddetails-mid {
        width: 50%;
        float: left;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 8px rgb(49 81 161 / 8%)
    }

    .leaddetails-right {
        width: 25%;
        float: left;
        margin-left: 2%;
        border-radius: 10px;
        box-shadow: 0px 0px 8px rgb(49 81 161 / 8%)
    }
    .vehicle-interest {
        padding: 10px
    }
    .detais-search {
        margin: 10px 0;
        position: relative;
    }
    .detais-search .form-group {
        margin-bottom: 0;
    }
    .vehicle-interest .Vehicle .form-control {
        display: block;
        width: 100%;
        height: auto;
        padding: 8px 8px 8px 35px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #A2A8B8;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #D5D8DF;
        border-radius: 3px;
    }
    .detais-search .Vehi-img {
        position: absolute;
        top: 9px;
        left: 10px;
        height: 18px;
        background: #fff;
        cursor: pointer
    }
    .card {
        background-color: #fff;
        background-clip: border-box;
        border: none;
        border-radius: 0
    }
    .card-header {
        padding: 0 !important;
        margin-bottom: 1px !important;
        background-color: #fff !important;
        border-bottom: 1px solid #dee2e6!important
    }
    .card-body {
        padding: 0 !important;
    }
    .appointments {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 24px;
        margin-bottom: 30px;
    }
    .appointments p {
        font-size: 14px;
        line-height: 1.5;
        font-weight: 00;
        color: #333;
        margin-top: 5px;
        text-transform: capitalize;
        margin-bottom: 7px
    }
    .appointments p img {
        margin-left: 10px;
        cursor: pointer
    }
    .details-profile {
        padding: 15px;
        text-align: center
    }
    .details-profile img{
        max-width: 100px;
    }
    .details-profile h3 {
        color: #343945;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
        text-transform: capitalize;
        margin: 5px 0
    }
    .details-profile .table {
        width: 100%;
        margin-bottom: 0;
        color: #212529;
        text-align: center;
        margin: 0 auto
    }
    .details-profile .table td,
    .details-profile .table th {
        color: #6D778E;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        padding: 5px;
        vertical-align: top;
        border-top: 1px solid transparent;
        border-bottom: 0;
        text-align: left;
    }
    .details-profile .table td:last-child {
        color: #343945;
    }
    .team-tags {
        padding: 0;
        text-align: left;
        border-top: 1px solid #ECEEF1;
        margin-top: 10px;
    }
    .team-tags ul {
        padding-left: 0
    }
    .team-tags li {
        list-style: none;
        display: inline;
    }
    .team-tags li a {
        display: inline-block;
        text-decoration: none;
        color: #6e317a;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.3;
        background: #f7f2fa;
        padding: 4px 15px;
        border-radius: 30px;
        margin-bottom: 5px;
        margin-right: 3px
    }
    .activity-area {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
        margin-bottom: 30px
    }
    .media {
        display: flex;
        align-items: flex-start
    }
    .message-content {
        height: 400px;
        overflow-x: hidden
    }
    .message-contentss {
        padding: 15px 15px 0px 15px
    }
    .activity {
        display: block;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px 0;
        overflow: hidden;
        border-top: 1px solid #D5D8DF
    }
    .activity-icon {
        width: 7%;
        float: left;
        overflow: hidden;
        min-height: 40px;
        display: block;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 2%
    }
    .activity-icon,.activity{
        display: block;
    }
    .activity-text {
        width: 90%;
        float: left;
        overflow: hidden
    }
    .message-box {
        position: relative
    }
    .message-box .form-control {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 3px;
        height: 50px
    }
    .message-box button {
        background: transparent;
        border: none;
        position: absolute;
        top: 10px;
        right: 4px;
        z-index: 9
    }
    .profile-page .card {
        margin-bottom: 0px;
        border-radius: unset;
    }
    @media screen and (max-width: 767px) {
        .leaddetails-left{
            width: 100%
        }
        .leaddetails-mid{
            width: 100%
        }
        .leaddetails-right{
            width: 100%;
            margin-left: auto
        }
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
        .leaddetails-left{
            width: 34%
        }
        .leaddetails-mid{
            width: 64%
        }
        .leaddetails-right{
            width: 100%;
            margin-left: auto
        }
    }
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        .leaddetails-left{
            width: 23%
        }
        .leaddetails-mid{
            width: 45%
        }
        .leaddetails-right{
            width: 28%
        }
        .details-profile{
            padding: 15px
        }
    }



    .status{
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        border-radius: 3px;
        text-decoration: none;
        padding: 4px 10px;
        height: 24px;
        min-width: 64px;
        width: auto;
        text-align: center;
    }
    .status-active{
        color: #19CD9D;
        background: rgba(25, 205, 157, 0.1);
    }
    .status-blue{
        color: #3151A1;
        background: #DBE5FF;
    }
    .status-red{
        color: #EB5757;
        background: rgba(235, 87, 87, 0.1);
    }

    textarea.chat-input {
        resize: none;
        overflow: hidden;
    }
    .user-active {
        background: #19CD9D;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        display: inline-block;
        /*position: absolute;*/
        top: 4px;
        right: -4px;
    }
</style>