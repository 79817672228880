<template>
    <!-- <div class="col-lg-3 col-md-12">
        <div class="card border">
            <div class="card-body text-center">
                <div class="profile-pic">

                    <div class="profile-pic-img">
                        <img src="@/assets/images/profile/Anne_Hathaway_Face.jpg" alt="User Profile Image" class="img-fluid">

                        <a href="javascript:void(0)" class="camera_icon bg-white" title="Upload Profile Image"><fa icon="camera" class="text-pink" /></a>
                        <input type="file" name="profile" accept="image/*" id="fileInput" style="display: none" onchange="angular.element(this).scope().file_changed(this)">
                    </div>
                    {{$storage.getStorageSync('user').picture}}
                    <a href="profile" class="text-dark">
                        <h5 class="mt-4 mb-0">{{$storage.getStorageSync('user').name}}</h5>
                    </a>
                </div>
                <div class="pt-2">
                    <a href="#" class="text-12">View Profile</a>
                </div>
            </div>
            <div class="item1-links  mb-0">
                <router-link :to="{name:'EditProfile'}" class="d-flex border-bottom" v-bind:class="this.$route.name == 'EditProfile' ? 'active' : ''">
                    <span class="icon1"><fa icon="user" /></span> Edit Profile
                </router-link>
                <router-link :to="{name:'userAppointments'}" class=" d-flex border-bottom" v-bind:class="this.$route.name == 'userAppointments' ? 'active' : ''">
                    <span class="icon1"><fa icon="calendar-check" /></span> Apointments
                </router-link>
                <router-link :to="{name:'userQueue'}" class=" d-flex border-bottom" v-bind:class="this.$route.name == 'userQueue' ? 'active' : ''">
                    <span class="icon1"><fa icon="tasks" /></span> Queue
                </router-link>
                <router-link :to="{name:'userFavourites'}" class=" d-flex border-bottom" v-bind:class="this.$route.name == 'userFavourites' ? 'active' : ''">
                    <span class="icon1"><fa icon="heart" /></span> Favourites
                </router-link>
                <a class="d-flex border-bottom" href="#">
                    <span class="icon1"><fa icon="star" /></span> Reviews
                </a>
                <router-link :to="{name:'Chat'}" class="d-flex border-bottom" v-bind:class="this.$route.name == 'Chat' ? 'active' : ''">
                    <span class="icon1"><fa icon="envelope" /></span> Messages
                </router-link>
                <a class="d-flex border-bottom" href="#">
                    <span class="icon1"><fa icon="list-ul" /></span> Transactions
                </a>
                <a data-toggle="modal" data-target="#bd-example-modal-sm" href="#" class="d-flex border-bottom">
                    <span class="icon1"><fa icon="user-plus" /></span> Invite People
                </a>
                <a class=" d-flex border-bottom" href="#">
                <span class="icon1"><i class="fa fa-shopping-cart"></i></span> Products
                </a>
                <a class="d-flex border-bottom" href="#">
                    <span class="icon1"><fa icon="bookmark" /></span> Bookmarks
                </a>
                <a class=" d-flex border-bottom" href="#">
                <span class="icon1"><fa icon="star" /><i class="fa fa-trophy"></i></span> Points
                </a>

                <a class="d-flex border-bottom" href="#">
                    <span class="icon1"><fa icon="cog" /></span> Settings
                </a>
                <a class="d-flex" href="#" v-on:click="logout">
                    <span class="icon1"><fa icon="sign-out-alt" /></span> Logout
                </a>
            </div>
        </div>
    </div> -->
    <div class="d-block d-lg-none">
        <div class="card border" :class="mobOpen ? 'toggle-sideba' : ''">
            <div class="text-center">
                <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/menu_bar.svg" class="pt-1 cursor_pointer" v-on:click="toggleMobDropdown()" style="width:30px">
            </div>
            <div class="card-body text-center" :class="mobOpen ? 'd-block' : 'd-none'">
                <div class="profile-pic">
                    <div class="profile-pic-img" style="width:max-content">
                        <img :src="imgDataUrl" alt="User Profile Image" class="img-fluid" :class="isOpen ? '' : 'rounded-circle'" />

                        <span class="menu_text">
                            <a
                                href="javascript:void(0)"
                                class="camera_icon bg-white"
                                title="Upload Profile Image"
                                @click="toggleShow"
                                ><fa icon="camera" class="text-pink" /></a>
                            <myUpload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" v-model="show" :width="200" :height="200" :url="url" :params="params" :headers="headers" img-format="jpg" langType="en" :noCircle="true"></myUpload>
                        </span>
                        <input
                            type="file"
                            name="upload"
                            accept="image/*"
                            id="upload_image"
                            class="d-none"
                        />
                    </div>
                    <a href="javascript:void(0)" class="text-dark">
                        <h5 class="mt-4 mb-0">
                            <span class="menu_text">
                                {{ $storage.getStorageSync("user").name }}</span
                            >
                        </h5>
                    </a>
                </div>
                <div class="pt-2">
                    <router-link :to="{ name: 'EditProfile' }" class="text-12" v-bind:class="this.$route.name == 'EditProfile' ? 'active' : ''">
                        <span class="menu_text">View Profile</span>
                    </router-link>
                </div>
            </div>
            <div class="item1-links mb-0" :class="mobOpen ? 'd-block' : 'd-none'">
                <router-link
                    :to="{ name: 'EditProfile' }"
                    class="d-flex"
                    v-bind:class="
                        this.$route.name == 'EditProfile' ? 'active' : ''
                    "
                >
                    <span class="icon1"><fa icon="user" /></span>
                    <span class="menu_text"> Edit Profile</span>
                </router-link>
                <router-link
                    :to="{ name: 'userAppointments' }"
                    class="d-flex"
                    v-bind:class="
                        this.$route.name == 'userAppointments' ? 'active' : ''
                    "
                >
                    <span class="icon1"><fa icon="calendar-check" /></span>
                    <span class="menu_text"> Appointments</span>
                </router-link>
                <router-link
                    :to="{name:'userQueue'}"
                    class=" d-flex"
                    v-bind:class="this.$route.name == 'userQueue' ? 'active' : ''"
                >
                    <span class="icon1"><fa icon="tasks" /></span>
                    <span class="menu_text"> Queue</span>
                </router-link>
                <!-- <a class=" d-flex border-bottom" href="#">
                <span class="icon1"><i class="fa fa-shopping-cart"></i></span> Products
                </a>
                <a class="d-flex border-bottom" href="#">
                    <span class="icon1"><fa icon="bookmark" /></span> Bookmarks
                </a> -->
                <!-- <router-link
                    :to="{ name: 'userFavourites' }"
                    class="d-flex border-bottom"
                    v-bind:class="
                        this.$route.name == 'userFavourites' ? 'active' : ''
                    "
                >
                    <span class="icon1"><fa icon="heart" /></span>
                    <span class="menu_text"> Favourites</span>
                </router-link> -->
                <!-- <a class=" d-flex border-bottom" href="#">
                <span class="icon1"><fa icon="star" /><i class="fa fa-trophy"></i></span> Points
                </a> -->
                <router-link
                    :to="{ name: 'userReviews' }"
                    class="d-flex"
                    v-bind:class="this.$route.name == 'userReviews' ? 'active' : ''"
                >
                    <span class="icon1"><fa icon="star" /></span>
                    <span class="menu_text"> Reviews</span>
                </router-link>
                <router-link
                    :to="{ name: 'Chat' }"
                    class="d-flex"
                    v-bind:class="this.$route.name == 'Chat' ? 'active' : ''"
                >
                    <span class="icon1"><fa icon="envelope" /></span>
                    <span class="menu_text"> Messages</span>
                </router-link>
                <!-- <a class="d-flex border-bottom" href="#">
                    <span class="icon1"><fa icon="list-ul" /></span>
                    <span class="menu_text">Transactions</span>
                </a> -->
                <a
                    href="void(0)"
                    class="d-flex"
                    data-bs-toggle="modal"
                    data-bs-target="#inviteModal"
                >
                    <span class="icon1"><fa icon="user-plus" /></span>
                    <span class="menu_text">Invite People </span>
                </a>
                <router-link
                    :to="{ name: 'UserSettings' }"
                    class="d-flex"
                    v-bind:class="this.$route.name == 'UserSettings' ? 'active' : ''"
                >
                    <span class="icon1"><fa icon="cog" /></span>
                    <span class="menu_text"> Settings</span>
                </router-link>
                <a class="d-flex" href="#" v-on:click.prevent="logout">
                    <span class="icon1"><fa icon="sign-out-alt" /></span>
                    <span class="menu_text"> Sign out</span>
                </a>
            </div>
        </div>
    </div>

    <div class="d-none d-lg-block" :class="isOpen ? 'col-lg-2 col-md-12' : 'col-lg-1 col-md-12'">
        <div class="card border" :class="isOpen ? '' : 'toggle-sidebar'">
            <div class="float-end" :class="isOpen ? 'text-end' : 'text-center'">
                <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/menu_bar.svg" class="pt-1 cursor_pointer" v-on:click="toggleSidebar()" style="width:30px">
                <!-- <div class="burgers" v-on:click="toggleSidebar()">
                    <div></div>
                    <div></div>
                    <div></div>
                </div> -->
            </div>
            <div class="card-body text-center">
                <div class="profile-pic">
                    <div class="profile-pic-img">
                        <img
                            :src="imgDataUrl"
                            alt="User Profile Image"
                            class="img-fluid"
                            :class="isOpen ? '' : 'rounded-circle'"
                        />

                        <span class="menu_text">
                            <a
                                href="javascript:void(0)"
                                class="camera_icon bg-white"
                                title="Upload Profile Image"
                                @click="toggleShow"
                                ><fa icon="camera" class="text-pink" /></a
                            >
                            <myUpload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" v-model="show" :width="200" :height="200" :url="url" :params="params" :headers="headers" img-format="jpg" langType="en" :noCircle="true"></myUpload>
                        </span>
                        <!-- <input
                            type="file"
                            name="upload"
                            accept="image/*"
                            id="upload_image"
                            class="d-none"
                        />
                        <img
                            src="@/assets/images/profile/Anne_Hathaway_Face.jpg"
                            alt="User Profile Image"
                            class="img-fluid"
                            :class="isOpen ? '' : 'rounded-circle'"
                        />

                        <span class="menu_text">
                            <a
                                href="javascript:void(0)"
                                class="camera_icon bg-white"
                                title="Upload Profile Image"
                                ><fa icon="camera" class="text-pink" /></a
                            >
                        </span>
                        <input
                            type="file"
                            name="profile"
                            accept="image/*"
                            id="fileInput"
                            style="display: none"
                            onchange="angular.element(this).scope().file_changed(this)"
                        /> -->
                    </div>
                    <a href="javascript:void(0)" class="text-dark">
                        <h5 class="mt-4 mb-0">
                            <span class="menu_text">
                                {{ $storage.getStorageSync("user").name }}</span
                            >
                        </h5>
                    </a>
                </div>
                <div class="pt-2">
                    <router-link :to="{ name: 'EditProfile' }" class="text-12" v-bind:class="this.$route.name == 'EditProfile' ? 'active' : ''">
                        <!-- <span class="menu_text">View Profile</span> -->
                    </router-link>
                </div>
            </div>
            <div class="item1-links mb-0">
                <router-link
                    :to="{ name: 'EditProfile' }"
                    class="d-flex border-bottom"
                    v-bind:class="
                        this.$route.name == 'EditProfile' ? 'active' : ''
                    "
                >
                    <span class="icon1"><fa icon="user" /></span>
                    <span class="menu_text"> Edit Profile</span>
                </router-link>
                <router-link
                    :to="{ name: 'userAppointments',params:{type:'upcoming'} }"
                    class="d-flex border-bottom"
                    v-bind:class="this.$route.name == 'userAppointments' ? 'active' : ''"
                >
                    <span class="icon1"><fa icon="calendar-check" /></span>
                    <span class="menu_text"> Appointments</span>
                </router-link>
                <router-link
                    :to="{name:'userQueue',params:{type:'today'}}"
                    class=" d-flex border-bottom"
                    v-bind:class="this.$route.name == 'userQueue' ? 'active' : ''"
                >
                    <span class="icon1"><fa icon="tasks" /></span>
                    <span class="menu_text"> Queue</span>
                </router-link>
                <!-- <a class=" d-flex border-bottom" href="#">
                <span class="icon1"><i class="fa fa-shopping-cart"></i></span> Products
                </a>
                <a class="d-flex border-bottom" href="#">
                    <span class="icon1"><fa icon="bookmark" /></span> Bookmarks
                </a> -->

                <!-- <a class=" d-flex border-bottom" href="#">
                <span class="icon1"><fa icon="star" /><i class="fa fa-trophy"></i></span> Points
                </a> -->
                <!-- <router-link
                    :to="{ name: 'userReviews' }"
                    class="d-flex border-bottom"
                    v-bind:class="this.$route.name == 'userReviews' ? 'active' : ''"
                >
                    <span class="icon1"><fa icon="star" /></span>
                    <span class="menu_text"> Reviews</span>
                </router-link> -->
                <router-link
                    :to="{ name: 'Chat' }"
                    class="d-flex border-bottom"
                    v-bind:class="this.$route.name == 'Chat' ? 'active' : ''"
                >
                    <span class="icon1"><fa icon="envelope" /></span>
                    <span class="menu_text"> Messages</span>
                </router-link>
                <!-- <a class="d-flex border-bottom" href="#">
                    <span class="icon1"><fa icon="list-ul" /></span>
                    <span class="menu_text">Transactions</span>
                </a> -->
                <a
                    href="void(0)"
                    class="d-flex border-bottom"
                    data-bs-toggle="modal"
                    data-bs-target="#inviteModal"
                >
                    <span class="icon1"><fa icon="user-plus" /></span>
                    <span class="menu_text">Invite People </span>
                </a>
                <router-link
                    :to="{ name: 'UserSettings' }"
                    class="d-flex border-bottom"
                    v-bind:class="this.$route.name == 'UserSettings' ? 'active' : ''"
                >
                    <span class="icon1"><fa icon="cog" /></span>
                    <span class="menu_text"> Settings</span>
                </router-link>
                <a class="d-flex" href="#" v-on:click.prevent="logout">
                    <span class="icon1"><fa icon="sign-out-alt" /></span>
                    <span class="menu_text"> Sign out</span>
                </a>
            </div>
        </div>
    </div>

    <div class="modal fade" id="inviteModal" tabindex="-1" aria-labelledby="inviteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="inviteModalLabel">Invitaion Box</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <input type="text" class="form-control input-lg"  name="referral_link" id="myInput" :value="referral_link">
                    <button class="btn btn-primary" id="copyme" v-on:click="copyURL"><fa icon="copy" /></button>
                    <div class="row mt-3 mb-2">
                        <div class="col-4 text-center">
                            <a class="btn btn-primary me-2 d-block" :href="'https://www.facebook.com/sharer/sharer.php?u='+referral_link" target="_blank"><fa :icon="['fab','facebook-f']" /></a>
                        </div>
                        <div class="col-4 text-center">
                            <a class="btn btn-info me-2 text-white d-block" :href="'http://twitter.com/share?text='+referral_link" target="_blank"><fa :icon="['fab','twitter']" /></a>
                        </div>
                        <div class="col-4 text-center">
                            <a class="btn btn-success d-block" :href="'whatsapp://send?text='+referral_link" data-action="share/whatsapp/share" target="_blank"><fa :icon="['fab','whatsapp']" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { useToast } from "vue-toastification"
    import myUpload from 'vue-image-crop-upload'

    export default {
        components: {
            myUpload
        },
        setup () {
            const toast = useToast();
            return {toast}
        },
        data() {
            return {
                isOpen: true,
                mobOpen: false,
                show: false,
                url: axios.defaults.baseURL+'update_user_profile_picture',
                params: {
                    picture: '',
                },
                headers: {
                    Authorization: 'Bearer '+this.$storage.getStorageSync('token')
                },
                imgDataUrl: this.assetUrl('profile/profile.svg'),
                referral_link: process.env.VUE_APP_URL+'/'+this.$storage.getStorageSync('user').name
            };
        },
        methods: {
            toggleShow() {
                this.show = !this.show
            },
            cropSuccess(imgUrl, field){
                console.log('-------- crop success --------'+field);
                this.imgDataUrl = imgUrl
                this.params.picture = imgUrl
            },
            cropUploadSuccess(data){
                console.log('-------- upload success --------');
                if(data.status == 'success'){
                    this.toast.success('Uploaded Successfully.')
                    this.$storage.setStorageSync("user",data.data)
                }
            },
            cropUploadFail(status){
                console.log('-------- upload fail --------');
                console.log(status);
            },
            toggleSidebar() {
                //alert()
                this.isOpen = !this.isOpen
            },
            toggleMobDropdown() {
                //alert()
                this.mobOpen = !this.mobOpen
            },
            logout(){
                let thiss = this
                axios.get('logout').then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push("home")
                        // location.reload();
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            thiss.toast.error(error.response.data.message);
                        }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                            thiss.$storage.removeStorageSync("user")
                            thiss.$storage.removeStorageSync("token")
                            thiss.$storage.removeStorageSync("business")
                            thiss.$router.push("home")
                        }
                    }
                });
            },
            copyURL(){
                var copyText = document.getElementById("myInput")
                copyText.select()
                copyText.select()
                document.execCommand("copy")
            }
        },
        mounted() {
            if(this.$storage.getStorageSync("user").picture){
                this.imgDataUrl = this.$storage.getStorageSync("user").picture
            }
        }
    }
</script>
<!-- <style type="text/css">
    .item1-links a.active {
        font-size: 30px
    }
</style> -->
<style scoped>
    .card{
        z-index: unset !important;
    }
    .burgers div {
        width: 35px;
        height: 5px;
        background-color: black;
        margin: 6px 0;
        /* float: right;
        display:inline; */
        /* position: relative;
        left:295px; */
    }
    .toggle-sidebar {
        max-width: 80px
    }
    .toggle-sidebar .menu_text {
        display: none
    }
    .item1-links a {
        padding: 0.75rem 1.9rem;
    }
    #copyme {
        position: absolute;
        right: 9px;
        top: 20px
    }
    @media screen and (max-width: 576px){
        .item1-links a, .item1-links a.active {
            font-size: 16px ;
        }
    }
</style>