<template>
    <the-header />
    <main class="profile-page">
        <div class="container-fluid">
            <div class="row">
                <side-bar/>
                <div class="col-lg-10 col-md-12">
                    <slot/>
                </div>
            </div>
        </div>
    </main>
    <the-footer />
</template>

<script>
import TheFooter from './../common/TheFooter'
import TheHeader from '@/components/common/header/TheHeader'
import SideBar from './../common/UserDashboardSideBar'
import 'bootstrap/dist/css/bootstrap.min.css'
import Echo from 'laravel-echo';
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

export default {
    name: 'UserDashboardContainer', 
    components: {
        TheFooter,
        TheHeader,
        SideBar
    },
    created(){
        axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$storage.getStorageSync('token')

        window.Pusher = require('pusher-js');

        window.Echo = new Echo({
            authEndpoint: process.env.VUE_APP_BASE_URL+'broadcasting/auth', 
            broadcaster: process.env.VUE_APP_BROADCAST_DRIVER,
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            encrypted: process.env.VUE_APP_WEBSOCKETS_ENCRYPT,
            wsHost: window.location.hostname,
            wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
            wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
            forceTLS: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    Authorization: 'Bearer ' + this.$storage.getStorageSync('token')
                },
            },
        });
    }
}
</script>
<style>
@import './../../assets/css/style.css';
</style>
<style>
.leads-table-area{
    box-shadow: none !important;
    /* border-radius: 5px !important;
    border: 1px solid #dee2e6; */
}
</style>