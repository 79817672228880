<template>
    <AppContainer>
        <Chat/>
    </AppContainer>
</template>

<script>
  import AppContainer from '@/components/containers/UserDashboardContainer.vue'
  import Chat from '@/components/userDashboard/messages/messages.vue'

  export default {
    components: {
      AppContainer,
      Chat
    }
  }
</script>